
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import { Options, Vue, prop } from 'vue-class-component'
import PDFEditor from '@/components/Editor/PDFEditor.vue'
import PSPDFKit from "pspdfkit"
import { faSave } from '@fortawesome/pro-light-svg-icons';

const faSaveAs = {
    icon: [
        576,
        512,
        "",
        'M266.7 448H80C53.49 448 32 426.5 32 400v-288c0-20.83 13.42-38.43 32-45.05V160c0 17.67 14.33 32 32 32h192c17.67 0 32-14.33 32-32V72.02c2.664 1.758 5.166 3.771 7.438 6.043l74.5 74.5c6.127 6.137 10.4 13.63 12.52 21.81c1.848 7.113 8.137 12.15 15.48 12.15c10.61 0 18.14-10.1 15.46-20.37c-3.545-13.58-10.64-26.03-20.84-36.21l-74.5-74.5C334.9 40.33 314.9 32 293.5 32H80C35.82 32 0 67.82 0 112v288C0 444.2 35.82 480 80 480h186.7c12.71 0 20.34-14.1 13.4-24.74C277.1 450.7 272.1 448 266.7 448zM96 64h192v96H96V64zM224 272c11.95 0 22.85 4.402 31.23 11.66c7.73 6.701 19.33 5.115 24.43-3.758C279.7 279.8 279.8 279.7 279.8 279.6c3.711-6.434 2.199-14.56-3.355-19.49C262.5 247.7 244.2 240 224 240c-44.03 0-79.84 35.73-80 79.7c-.1445 39.62 30.22 74.11 69.47 79.56c11.34 1.576 22.14 .5313 32.16-2.402c6.994-2.045 11.73-8.553 11.4-15.83c-.0078-.1484-.0137-.2988-.0195-.4473c-.4531-10.34-10.47-17.04-20.45-14.29c-7.441 2.059-15.61 2.344-24.08 .3301c-17.66-4.201-31.77-18.69-35.44-36.47C170.7 299.3 194.2 272 224 272zM432 224c-79.54 0-144 64.46-144 144s64.46 144 144 144c79.54 0 143.1-64.46 143.1-144S511.5 224 432 224zM432 480C370.2 480 320 429.8 320 368S370.2 256 432 256C493.8 256 544 306.2 544 368S493.8 480 432 480zM507.3 356.7l-56-56c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L457.4 352h-89.38c-8.836 0-15.1 7.164-15.1 16s7.163 16 15.1 16h89.38l-28.69 28.69c-6.25 6.25-6.25 16.38 0 22.62C431.8 438.4 435.9 440 439.1 440s8.188-1.562 11.31-4.688l56-56C513.6 373.1 513.6 362.9 507.3 356.7z'
    ]
}

@Options({
    components: {
        PDFEditor,
    },
})
export default class PDFEditorView extends Vue {
    private fileid: string = ''
    private store_in_folder_id: string = ''

    created() {
        this.fileid = this.$route.hash.split("#")[1] as string
        if (!this.fileid) {
            this.store_in_folder_id = this.$store.state.editor_folder_id;
        }
        console.log("faSave", faSave);
    }

    private save() {
        console.log("save");
        //@ts-ignore
        this.$refs.PDFEditor.save();
    }

    get toolbar_buttons() {

        const def = PSPDFKit.defaultToolbarItems;

        console.log("DEFAULT", def)

        const replace_settings = [
            {
                type: 'zoom-out',
            },
            {
                type: 'zoom-in',
            },
            {
                type: 'text',
                title: "Add Text",
                dropdownGroup: 'edit-text'
            }
        ]

        const delete_settings = [
            {
                type: "multi-annotations-selection"
            }
        ]

        const add_settings: any = [
            {
                afterType: 'zoom-mode',
                type: 'layout-config',
            },
        ]

        // If afterType == type, add the item to the list after the item, do not replace it

        let added_settings = def.map((item: any) => {
            for (let i = 0; i < add_settings.length; i++){
                if (item.type === add_settings[i].afterType) {
                    delete add_settings[i].afterType
                    //Insert the item at this position
                    return [item, add_settings[i]]
                }
            }
            return item
        })

        added_settings = added_settings.flat()

        const filtered_settings = added_settings.filter((item: any) => {
            for (let i = 0; i < delete_settings.length; i++){
                if (item.type === delete_settings[i].type) {
                    return false
                }
            }
            return true
        })

        const new_settings = filtered_settings.map((item: any) => {
            for (let i = 0; i < replace_settings.length; i++){
                if (item.type === replace_settings[i].type) {
                    return replace_settings[i]
                }
            }
            return item
        })

        return [
            {
                type: 'custom',
                id: 'save',
                title: 'Save current File',
                icon: `<div style="width: 32px; height: 32px; transform: scale(0.04) translate(-300px, -230px);"><svg width=${faSave.icon[0]} height=${faSave.icon[1]}><path d="${faSave.icon[4]}"/></svg></div>`,
                dropdownGroup: 'save-group',
                onPress: (event: any) => {
                    this.save();
                }
            },
            ...new_settings,
            {
                type: 'content-editor',
                title: "Edit Text",
                dropdownGroup: 'edit-text',
                className: 'contentEditorCustom'
            },
            //{
            //    type: 'custom',
            //    id: 'save-as-new-file',
            //    title: 'Save as new File',
            //    icon: `<div style="width: 32px; height: 32px; transform: scale(0.04) translate(-300px, -230px);"><svg width=${faSaveAs.icon[0]} height=${faSaveAs.icon[1]}><path d="${faSaveAs.icon[3]}"/></svg></div>`,
            //    dropdownGroup: 'save-group',
            //    onPress: (event: any) => {
            //        this.save();
            //    }
            //},
        ]
    }
}
