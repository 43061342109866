
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import { Options, Vue, prop } from 'vue-class-component'
import DocEditor from '@/components/Editor/DocEditor.vue'

@Options({
    components: {
        DocEditor,
    },
})
export default class DocEditorView extends Vue {
    private fileid: string = ''
    private store_in_folder_id: string = ''

    created() {
        this.fileid = this.$route.hash.split("#")[1] as string
        if (!this.fileid) {
            this.store_in_folder_id = this.$store.state.editor_folder_id;
        }
    }
}
