import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "file-page full-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFEditor = _resolveComponent("PDFEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PDFEditor, {
      ref: "PDFEditor",
      "file-id": _ctx.fileid,
      "folder-id": _ctx.store_in_folder_id,
      "toolbar-items": _ctx.toolbar_buttons
    }, null, 8, ["file-id", "folder-id", "toolbar-items"])
  ]))
}